<template>
  <div class="accounting-menu">
    <Banner>
      <div class="banner-content">
        <MeepIconListAccounting class="banner-content__icon" />
        <span class="banner-content__text">{{
          $t("menu.accouting-plan")
        }}</span>
      </div>
    </Banner>
    <div class="accounting-menu-cards page-layout">
      <SettingCard
        :title="$t('accounting-menu.general')"
        :content="$t('accounting-menu.general-content')"
        link="/dashboard/accounting-plan/menu/view/general"
        :button-text="$t('accounting-menu.button-text')"
      />
      <SettingCard
        :title="$t('accounting-menu.association')"
        :content="$t('accounting-menu.association-content')"
        link="/dashboard/accounting-plan/menu/view/association"
        :button-text="$t('accounting-menu.button-text')"
      />
      <SettingCard
        :title="$t('accounting-menu.sci')"
        :content="$t('accounting-menu.sci-content')"
        link="/dashboard/accounting-plan/menu/view/sci"
        :button-text="$t('accounting-menu.button-text')"
      />
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import SettingCard from "@/components/SettingCard";
import MeepIconListAccounting from "@/components/icons/MeepIconListAccounting.vue";

export default {
  name: "AccountingMenu",
  components: {
    Banner,
    SettingCard,
    MeepIconListAccounting,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.accounting-menu {
  &-cards {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .setting-card {
    &-text {
      padding: 0 0 35px 0;
    }
  }
}
</style>
